import moment from "moment/moment";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {Heading, Skeleton} from "@chakra-ui/react";
import {processDataGroup} from "../../utilities/ProcessData";
import * as React from "react";
import {filterIndicatorDataEntriesForIndicator} from "../../../../services/IndicatorDataEntryService";
import {EntitySearch} from "../../../../entities/request/EntitySearch";
import {FieldSearch} from "../../../../entities/request/FieldSearch";
import {SearchTypes} from "../../../../enums/SearchTypes";
import {getEndRecordingDateForDate, getStartRecordingDateForDate} from "../../../../helpers/ApplicationHelper";
import {CellType, ColumnDataType, ComplexCell, Table} from "../../GenericCharts/Table";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {
    getQuarter,
    getSemester,
    getYearQuarterCount,
    getYears,
    getYearSemesterCount
} from "../../../../helpers/DateHelper";
import {IGroupedSeriesData} from "../../interfaces/GroupedData";
import {getNoOfRowsForIndicator} from "../../ReportsHelper";

export const TableDataForPastNQuarters = (
    props : {
        chartName : string,
        quarterCount : number,
        reportDuration : moment.Moment
        indicatorDataEntry? : IndicatorDataEntryResponse,
        indicatorDataEntries? : IndicatorDataEntryResponse[],
        indicatorData : IndicatorResponse,
        indicatorDataType: IndicatorDataType,
        onChartImageURI: any
    }
) => {

    const {
        chartName,
        quarterCount,
        reportDuration,
        indicatorDataEntry,
        indicatorDataEntries,
        indicatorData,
        indicatorDataType,
        onChartImageURI
    } = props;

    const { t, i18n } = useTranslation();
    const selectedLanguage = i18n.language as SelectedLanguage;

    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [allRelevantIndicatorEntries, setAllRelevantIndicatorEntries] = React.useState<IndicatorDataEntryResponse[] | null>(null)

    React.useEffect(() => {
        if(indicatorDataEntries) {
            setAllRelevantIndicatorEntries(indicatorDataEntries);
            setIsLoading(false);
        }
        else if(indicatorDataEntry) {
            filterIndicatorDataEntriesForIndicator(
                indicatorData.indicatorNumber,
                new EntitySearch([
                    new FieldSearch(
                        SearchTypes.VALUE_EQUAL,
                        'indicatorNumber',
                        indicatorData.indicatorNumber
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_EQUAL,
                        'indicatorDataType',
                        indicatorDataType
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_GREATER_THAN_EQUAL_TO,
                        'recordingDate',
                        getStartRecordingDateForDate(
                            moment(reportDuration).subtract(quarterCount - 1, 'quarter').toDate(), indicatorDataType
                        )
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                        'recordingDate',
                        getEndRecordingDateForDate(
                            reportDuration.toDate(), indicatorDataType
                        )
                    )
                ])
            ).then(
                (indicatorDataEntryResponses : IndicatorDataEntryResponse[]) => {
                    setAllRelevantIndicatorEntries(indicatorDataEntryResponses);
                    setIsLoading(false);
                }
            )
        }
    }, [
        indicatorDataEntries,
        indicatorDataEntry,
        indicatorDataType,
        reportDuration
    ]);


    const getOtherHeaders = () => {

        const headers: ComplexCell[] = [];

        switch (indicatorDataType) {
            case IndicatorDataType.AURA_REPORTING:
                Object.entries(getYearQuarterCount(reportDuration, quarterCount)).map(([year, yearGroupCounts]) => {
                    headers.push({
                        value: year,
                        cellType: CellType.complex,
                        colSpan: yearGroupCounts,
                        rowSpan: 1,
                    });

                    for (let j=0; j<yearGroupCounts;j++) {
                        headers.push({
                            value: "",
                            cellType: CellType.discard,
                            colSpan: 0,
                            rowSpan: 1,
                        });
                    }

                })
                break;
            case IndicatorDataType.AURA_COMPLEMENTARY:
                getYears(reportDuration, quarterCount/4).forEach(year =>
                    headers.push({
                        value: year,
                        cellType: CellType.complex,
                        colSpan: 1,
                        rowSpan: 2,
                    })
                )
                break;
            case IndicatorDataType.DNAAS_USP:
                getYears(reportDuration, quarterCount/4).forEach(year =>
                    headers.push({
                        value: year,
                        cellType: CellType.complex,
                        colSpan: 1,
                        rowSpan: 2,
                    })
                )
                break;
        }
        return headers;
    }


    const getColumnsDataForIndicatorVsTimePeriod = (
        reportDuration: moment.Moment,
        groupedSeriesData: IGroupedSeriesData,
        indicatorDataType: IndicatorDataType,
        quarterCount: number,
        selectedLanguage: SelectedLanguage
    ): ColumnDataType[] => {

        const zoneColumns: ColumnDataType[] = [];

        const dateMoment = moment(reportDuration);

        while(quarterCount > 0) {
            const quarterOrSemesterOrYear =
                (indicatorDataType === IndicatorDataType.AURA_REPORTING)
                    ? `Q${getQuarter(dateMoment.toDate())}`
                    : (indicatorDataType === IndicatorDataType.DNAAS_USP)
                        ? `Year` : `Year`;
            const year = dateMoment.year();

            switch (indicatorDataType) {
                case IndicatorDataType.AURA_REPORTING:
                    quarterCount--;
                    dateMoment.subtract(1, 'quarter')
                    break;
                case IndicatorDataType.AURA_COMPLEMENTARY:
                    quarterCount-=4;
                    dateMoment.subtract(1, 'year')
                    break;
                case IndicatorDataType.DNAAS_USP:
                    quarterCount-=4;
                    dateMoment.subtract(1, 'year')
                    break;
            }

            const entries = groupedSeriesData.groupedData[year];
            if (entries) {
                const entry = entries.find(e => e.entryName === quarterOrSemesterOrYear + " " + year.toString());
                if (entry) {
                    zoneColumns.push(
                        {
                            header:
                                indicatorDataType === IndicatorDataType.AURA_COMPLEMENTARY
                                || indicatorDataType === IndicatorDataType.DNAAS_USP
                                    ? { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 2 }
                                    : quarterOrSemesterOrYear,
                            singleValues: entry.individualEntries,
                            stars: [],
                            values: [],
                            notApplicable : entry.notApplicable,
                            notReported : entry.notReported,
                            targets: [],
                            coloringFunctions: [],
                            iconFunction: null
                        }
                    );
                } else {
                    zoneColumns.push(
                        {
                            header:
                                indicatorDataType === IndicatorDataType.AURA_COMPLEMENTARY
                                || indicatorDataType === IndicatorDataType.DNAAS_USP
                                    ? { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 2 }
                                    : quarterOrSemesterOrYear,
                            singleValues: [],
                            stars: [],
                            values: [],
                            notApplicable : [],
                            notReported : [],
                            targets: [],
                            coloringFunctions: [],
                            iconFunction: null
                        }
                    );
                }
            } else {
                zoneColumns.push(
                    {
                        header:
                            indicatorDataType === IndicatorDataType.AURA_COMPLEMENTARY
                            || indicatorDataType === IndicatorDataType.DNAAS_USP

                                ? { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 2 }
                                : quarterOrSemesterOrYear,
                        singleValues: [],
                        stars: [],
                        values: [],
                        notApplicable : [],
                        notReported : [],
                        targets: [],
                        coloringFunctions: [],
                        iconFunction: null
                    }
                );
            }

        }

        return [
            {
                header: { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 2 },
                singleValues: groupedSeriesData.indicator.indicatorDataTypeStructureList[indicatorDataType]?.indicatorDataTypeEntryColumns.map(indicatorColumn => {
                    return indicatorColumn.displayName[selectedLanguage];
                }) ?? [],
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                iconFunction: null,
                coloringFunctions: []
            },
            {
                header: { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 2 },
                singleValues: groupedSeriesData.indicator.indicatorDataTypeStructureList[indicatorDataType]?.indicatorDataTypeEntryColumns.map(indicatorColumn => {
                    return indicatorColumn.description[selectedLanguage];
                }) ?? [],
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                iconFunction: null,
                coloringFunctions: []
            },
            ...(zoneColumns.reverse())
        ];
    }

    return (
        <>
                <Skeleton
                    isLoaded={!isLoading && allRelevantIndicatorEntries !== null}
                >
                        {
                            allRelevantIndicatorEntries && (
                                <>
                                    <Heading size={'md'}>{chartName}</Heading>
                                    <Table
                                        name={chartName}
                                        tableId={chartName}
                                        headers={[
                                            { value: indicatorData.name[selectedLanguage], cellType: CellType.complex, colSpan: 2, rowSpan: 2},
                                            ...getOtherHeaders()
                                        ]}
                                        columns={
                                            getColumnsDataForIndicatorVsTimePeriod(
                                                reportDuration,
                                                processDataGroup(
                                                    indicatorData,
                                                    allRelevantIndicatorEntries,
                                                    indicatorDataType,
                                                    selectedLanguage
                                                ),
                                                indicatorDataType, quarterCount,
                                                selectedLanguage
                                            )
                                        }
                                        noOfRows={getNoOfRowsForIndicator(indicatorData, indicatorDataType)} />
                                </>
                            )
                        }
                </Skeleton>
        </>
    );

}
