import {useTranslation} from "react-i18next";
import {Button, Flex, useToast} from "@chakra-ui/react";
import React from "react";
import {ICompany} from "../routes/CompaniesList";
import {getCompanySelectedFromLocalStorage} from "../utilities/LocalStorageUtil";
import {getAllIndicators, sendIndicatorToFirstPhase, sendIndicatorToNextPhase} from "../services/IndicatorService";
import {
    IndicatorDataEntryPhaseStructureResponse,
    IndicatorDataEntryPhaseSupportedDataInputsResponse,
    IndicatorDataTypeEntryColumnDataType,
    IndicatorDataTypeEntryColumnListValuesResponse,
    IndicatorDataTypeEntryColumnNumberValueResponse,
    IndicatorDataTypeEntryColumnResponse,
    IndicatorResponse
} from "../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../enums/IndicatorDataType";
import {createIndicatorDataEntry, patchIndicatorDataEntryForIndicator} from "../services/IndicatorDataEntryService";
import {
    IndicatorDataColumnEntryResponse
} from "../entities/response/indicatorDataColumnEntry/IndicatorDataColumnEntryResponse";
import {IndicatorDataEntryResponse} from "../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {IndicatorNumber} from "../enums/IndicatorNumber";
import {patchCompany} from "../services/CompanyService";
import {toastDuration} from "../constants/ApplicationConstants";
import {getCurrentAppEnvironment} from "../helpers/EnvironmentHelper";
import {AppEnvironment} from "../enums/AppEnvironment";

export const AddSampleData = () => {

    const {t} = useTranslation();

    const toast = useToast();

    const sampleDataDurationInYears = 5;

    const [isSampleDataAdding, setIsSampleDataAdding] = React.useState<boolean>(false);

    const [companySelected, setCompanySelected] = React.useState<ICompany | null>(getCompanySelectedFromLocalStorage())

    const addSampleData = async () => {
        setIsSampleDataAdding(true)

        if(companySelected) {
            for (const indicatorResponse of (await getAllIndicators())) {
                await addSampleDataForIndicator(indicatorResponse);
            }

            await patchCompany(companySelected.companyId, {sampleDataAdded : true})

            setCompanySelected(getCompanySelectedFromLocalStorage());
        }

        setIsSampleDataAdding(false)
    }


    const addSampleDataForIndicator = async (indicatorResponse : IndicatorResponse) => {
        return Promise.all(
            Object.keys(IndicatorDataType)
                .map(indicatorDataTypeKey =>
                    addSampleDataForIndicatorDataType(
                        indicatorResponse,
                        IndicatorDataType[indicatorDataTypeKey as keyof typeof IndicatorDataType]
                    )
                    // sendIndicatorToFirstPhase(indicatorResponse._id, IndicatorDataType[indicatorDataTypeKey as keyof typeof IndicatorDataType])
                )
        )
    }

    const addSampleDataForIndicatorDataType = async (indicatorResponse : IndicatorResponse, indicatorDataType : IndicatorDataType) => {
        if(
            indicatorDataType === IndicatorDataType.AURA_COMPLEMENTARY
            && indicatorResponse.indicatorNumber === IndicatorNumber.INDICATOR_6
        ) return;

        for(let i = 0; i<getNumberOfIndicatorDataEntriesToBeAdded(indicatorDataType); i++) {
            // create indicator entry
            const createdIndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await createSampleIndicatorDataEntry(indicatorResponse, indicatorDataType);

            toast({
                title: `Created ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name})`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // send to phase 2
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 2`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 2
            const patchPhase2IndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    createdIndicatorDataEntryResponse,
                    'phase2',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 2`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });


            // send to phase 3
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 3`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 3
            const patchPhase3IndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    patchPhase2IndicatorDataEntryResponse,
                    'phase3',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 3`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // send to phase 4a
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 4a`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 4a
            const patchPhase4aIndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    patchPhase3IndicatorDataEntryResponse,
                    'phase4a',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 4a`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            // send to phase 4b
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 4b`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 4b
            const patchPhase4bIndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    patchPhase4aIndicatorDataEntryResponse,
                    'phase4b',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 4b`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            // send to phase 5
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 5`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 5
            const patchPhase5IndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    patchPhase4bIndicatorDataEntryResponse,
                    'phase5',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 5`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            // send to phase 6
            await sendIndicatorToNextPhase(indicatorResponse._id, indicatorDataType);
            toast({
                title: `Sent ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) to Phase 6`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            // patch phase 6
            const patchPhase6IndicatorDataEntryResponse : IndicatorDataEntryResponse =
                await patchSampleIndicatorDataEntry(
                    patchPhase5IndicatorDataEntryResponse,
                    'phase6',
                    indicatorResponse,
                    indicatorDataType
                );
            toast({
                title: `Saved ${indicatorResponse.indicatorNumber} (${indicatorDataType} - ${createdIndicatorDataEntryResponse.name}) Phase 6`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            await sendIndicatorToFirstPhase(indicatorResponse._id, indicatorDataType);
        }
    }


    const createSampleIndicatorDataEntry = async (indicatorResponse: IndicatorResponse, indicatorDataType: IndicatorDataType) : Promise<IndicatorDataEntryResponse> => {
        const approvalProcessDataMap : {[key : string]: {[key : string]: IndicatorDataColumnEntryResponse}} = {};
        approvalProcessDataMap['phase1'] = getApprovalProcessDataMap(indicatorResponse, indicatorDataType, 'phase1')
        return await createIndicatorDataEntry(
            indicatorResponse.indicatorNumber,
            {
                indicatorDataType: indicatorDataType,
                fieldsValues: [],
                securityGroupsIds: [],
                // @ts-ignore
                approvalProcessDataMap: approvalProcessDataMap,
            }
        );
    }

    const patchSampleIndicatorDataEntry = async (
        indicatorDataEntryResponse : IndicatorDataEntryResponse,
        phaseKey : string,
        indicatorResponse: IndicatorResponse,
        indicatorDataType: IndicatorDataType
    ) : Promise<IndicatorDataEntryResponse> => {
        const approvalProcessDataMap = indicatorDataEntryResponse.approvalProcessDataMap;

        approvalProcessDataMap[phaseKey] =
            getApprovalProcessDataMap(indicatorResponse, indicatorDataType, phaseKey)

        return await patchIndicatorDataEntryForIndicator(
            indicatorDataEntryResponse._id,
            indicatorResponse.indicatorNumber,
            {
                approvalProcessDataMap: approvalProcessDataMap,
            }
        );
    }

    const getApprovalProcessDataMap = (
        indicatorResponse : IndicatorResponse, indicatorDataType : IndicatorDataType, phaseKey : string
    ) : {[key : string]: IndicatorDataColumnEntryResponse} => {

        const approvalProcessDataMap : {[key : string]: IndicatorDataColumnEntryResponse} = {};

        const supportedDataInputs : IndicatorDataEntryPhaseSupportedDataInputsResponse | undefined =
            indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].phaseSequence.find(
                (indicatorDataEntryPhaseStructure : IndicatorDataEntryPhaseStructureResponse) =>
                    indicatorDataEntryPhaseStructure.name === phaseKey
            )?.supportedDataInputs;

        if(supportedDataInputs) {
            indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.forEach(
                (indicatorDataTypeEntryColumnResponse : IndicatorDataTypeEntryColumnResponse) => {
                    approvalProcessDataMap[indicatorDataTypeEntryColumnResponse.name] = getIndicatorDataColumnEntryResponse(
                        indicatorDataTypeEntryColumnResponse,
                        supportedDataInputs,
                        indicatorResponse,
                        indicatorDataType,
                        phaseKey
                    );
                }
            )
        }

        return approvalProcessDataMap;
    }


    const getIndicatorDataColumnEntryResponse = (
        indicatorDataTypeEntryColumnResponse: IndicatorDataTypeEntryColumnResponse,
        supportedDataInputs : IndicatorDataEntryPhaseSupportedDataInputsResponse,
        indicatorResponse: IndicatorResponse,
        indicatorDataType: IndicatorDataType,
        phaseKey: string
    ) : IndicatorDataColumnEntryResponse => {
        const notApplicableSupported = (indicatorDataTypeEntryColumnResponse.notApplicableSupported || []).includes(phaseKey)
        const notReportedSupported = (indicatorDataTypeEntryColumnResponse.notReportedSupported || []).includes(phaseKey)

        const indicatorDataColumnEntryResponse : IndicatorDataColumnEntryResponse = {
            value : null,
            notes : null,
            attachmentPaths : [],
            additionalNotes : null,
            notApplicable : false,
            notReported : false
        };

        if(notApplicableSupported && returnTrueWithLikelyNess(30)) {
            indicatorDataColumnEntryResponse.notApplicable = true;
            return indicatorDataColumnEntryResponse;
        }

        if(notReportedSupported && returnTrueWithLikelyNess(30)) {
            indicatorDataColumnEntryResponse.notReported = true;
            return indicatorDataColumnEntryResponse;
        }

        const indicatorDataTypeEntryColumnDataType : IndicatorDataTypeEntryColumnDataType =
            indicatorDataTypeEntryColumnResponse.dataValidation.columnDataType

        if(supportedDataInputs.value) {
            switch (indicatorDataTypeEntryColumnDataType) {
                case IndicatorDataTypeEntryColumnDataType.LIST:
                    indicatorDataColumnEntryResponse.value = getRandomValueFromArray(
                        (indicatorDataTypeEntryColumnResponse.dataValidation as IndicatorDataTypeEntryColumnListValuesResponse)
                            .values
                    );
                    break;
                case IndicatorDataTypeEntryColumnDataType.STRING:
                    indicatorDataColumnEntryResponse.value = getRandomSentence(3, 5);
                    break;
                case IndicatorDataTypeEntryColumnDataType.NUMBER:
                    indicatorDataColumnEntryResponse.value = getRandomNumber(
                        (indicatorDataTypeEntryColumnResponse.dataValidation as IndicatorDataTypeEntryColumnNumberValueResponse)
                            .gte,
                        (indicatorDataTypeEntryColumnResponse.dataValidation as IndicatorDataTypeEntryColumnNumberValueResponse)
                            .lte
                    ).toString();
                    break;
            }
        }

        if(supportedDataInputs.notes) {
            indicatorDataColumnEntryResponse.notes = getRandomSentence(5, 4);
        }

        if(supportedDataInputs.additionalNotes) {
            indicatorDataColumnEntryResponse.additionalNotes = getRandomSentence(5, 4);
        }

        return indicatorDataColumnEntryResponse;
    }

    const getNumberOfIndicatorDataEntriesToBeAdded = (indicatorDataType : IndicatorDataType) => {
        switch (indicatorDataType) {
            case IndicatorDataType.AURA_REPORTING: return sampleDataDurationInYears * 4; // quarter
            case IndicatorDataType.AURA_COMPLEMENTARY: return sampleDataDurationInYears; // year
            case IndicatorDataType.DNAAS_USP: return sampleDataDurationInYears; // year
        }
    }

    const getRandomValueFromArray = (array : string[]) : string => {
        return array[getRandomInt(0, array.length)]
    }

    const getRandomNumber = (gte? : number, lte? : number) : number => {
        return getRandomInt(gte || 0, lte || 1000);
    }

    const getRandomWord = (length : number) : string => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const getRandomSentence = (words : number, wordLength : number) : string => {
        return Array.from(Array(getRandomInt(1, words))).map(x => getRandomWord(wordLength)).join(' ');
    }

    const getRandomInt = (min: number, max : number) : number => {
        return min + Math.floor(Math.random() * max);
    }

    const returnTrueWithLikelyNess = (likelinessPercentage : number) => {
        if(getRandomInt(0, 101) <= likelinessPercentage) return true;
        return false;
    }

    return(
        <>
            {
                !companySelected?.companySampleDataAdded && getCurrentAppEnvironment() === AppEnvironment.QAL && (
                    <Flex justifyContent={"flex-end"}>
                        <Button
                            colorScheme="blue"
                            isLoading={isSampleDataAdding}
                            loadingText='Adding Sample Data'
                            onClick={addSampleData}
                            spinnerPlacement='end'
                        >
                            {t("Add Sample Data")}
                        </Button>
                    </Flex>
                )
            }
        </>
    );
};
