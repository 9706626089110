import * as React from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {useTranslation} from "react-i18next";
import {Box, Button, Center, Flex} from "@chakra-ui/react";
import {DownloadIcon} from "@chakra-ui/icons";
import {ExportToExcel} from "../../../utilities/Export";

export enum CellType {
    complex = 'complex',
    discard = 'discard'
}

export interface ComplexCell {
  value: number | string;
  cellType: CellType;
  rowSpan: number;
  colSpan: number;
}

export interface ColumnDataType {
    values: (number | null)[];
    notApplicable: (boolean | null | undefined)[];
    notReported: (boolean | null | undefined)[];
    targets: number[];
    stars: number[];
    coloringFunctions: ((val: number) => string)[];
    iconFunction: ((val: number, target: number) => string) | null;
    singleValues: (number | string | ComplexCell)[] | undefined;
    header: string | ComplexCell;
}

export interface TableDataType {
    columns: ColumnDataType[];
    headers?: ComplexCell[];
    tableId: string;
    name: string;
    noOfRows: number;
}

export const Table = (props: TableDataType): JSX.Element => {
    const {columns, noOfRows, headers} = props;
    const rows = new Array<string>(noOfRows).fill('row');
    const {t} = useTranslation();

    const exportTable = () => {
        ExportToExcel(props.tableId, undefined, props.name + '.xlsx');
    }

    return <div id={props.tableId}>
        <Flex justifyContent={'flex-end'} mb={2}>
            <Button onClick={exportTable} colorScheme={'red'}>
                <DownloadIcon />
            </Button>
        </Flex>
        <Box maxH={400} className={"table-responsive"} >
            <MDBTable bordered striped borderColor={"dark"}>
                <MDBTableHead>
                    {
                        headers ? <tr>
                            {
                                headers.map(header => {
                                    if (header.cellType === CellType.discard)
                                        return null;
                                    return <th style={{backgroundColor: '#9c9cff', verticalAlign: 'middle'}} colSpan={header.colSpan} rowSpan={header.rowSpan}>{t(`${header.value}`)}</th>
                                })
                            }
                        </tr> : null
                    }
                    <tr>
                        {
                            columns.map(column => {
                                if((column.header as ComplexCell)?.cellType) {
                                    const columnHeader : ComplexCell = column.header as ComplexCell;
                                    switch (columnHeader.cellType) {
                                        case CellType.discard:
                                            return null;
                                        case CellType.complex:
                                            return <th style={{backgroundColor: '#9c9cff', verticalAlign: 'middle'}} colSpan={columnHeader.colSpan} rowSpan={columnHeader.rowSpan}>{t(`${columnHeader.value}`)}</th>
                                    }
                                }
                                return <th style={{backgroundColor: '#9c9cff', verticalAlign: 'middle'}} >{t(`${column.header}`)}</th>
                            })
                        }
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {rows.map((row, i) => {
                        return <tr>
                            {
                                columns.map((column) => {
                                    if (column.singleValues !== undefined) {
                                        if ((column.singleValues[i] as ComplexCell)?.cellType) {
                                            const complexCell = column.singleValues[i] as ComplexCell;
                                            return complexCell.cellType === 'complex' ?
                                                <td rowSpan={complexCell.rowSpan} style={{verticalAlign: 'middle'}}>
                                                    <Center height={"100%"} width={"100%"} m={1}>
                                                        <Flex flexDirection={'row'} height={"100%"} width={"100%"} alignItems={"center"} alignContent={"center"} justifyContent={"flex-start"}>
                                                            {t(`${complexCell.value}`)}
                                                        </Flex>
                                                    </Center>
                                                </td> :
                                                null;
                                        }

                                        return <td style={{verticalAlign: 'middle'}}>
                                            <Center height={"100%"} width={"100%"} m={1}>
                                                <Flex flexDirection={'row'} height={"100%"} width={"100%"} alignItems={"center"} alignContent={"center"} justifyContent={"flex-start"}>
                                                    {
                                                        column.notApplicable[i]
                                                            ? <td style={{verticalAlign: 'middle'}}><i>{t("NA")}</i></td>
                                                            : column.notReported[i]
                                                                ? <td style={{verticalAlign: 'middle'}}><i>{t("NR")}</i></td>
                                                                : t(`${column.singleValues[i] || '-'}`)
                                                    }
                                                </Flex>
                                            </Center>
                                        </td>
                                    }

                                    const columnValue : number | null = column.values[i];
                                    const columnTarget : number = column.targets[i];
                                    const columnStars : number = column.stars[i];

                                    if (column.notApplicable[i]) {
                                        return <td style={{verticalAlign: 'middle'}}><i>{t("NA")}</i></td>
                                    }

                                    if (column.notReported[i]) {
                                        return <td style={{verticalAlign: 'middle'}}><i>{t("NR")}</i></td>
                                    }

                                    if (!columnValue) {
                                        return <td style={{verticalAlign: 'middle'}}><i>-</i></td>
                                    }

                                    if (column.targets[i] === -1 && column.values[i] === 0)
                                        return <td style={{verticalAlign: 'middle'}}>-</td>;

                                    let color = 'inherit';
                                    if (column.coloringFunctions[i]) {
                                        color = column.coloringFunctions[i](columnValue);
                                    }
                                    let icon = undefined;
                                    if (column.iconFunction) {
                                        icon = column.iconFunction(columnValue,column.targets[i] === -1 ? 0.1 : column.targets[i]);
                                    }

                                    if (!column.values[i] && !column.targets[i])
                                        return <td style={{verticalAlign: 'middle'}}>-</td>;

                                    return <td style={{background: color, verticalAlign: 'middle'}}>
                                        <Center width={"100%"} m={1}>
                                            <Flex flexDirection={'row'} width={"100%"} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                                                <Flex width={"40%"} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                                                    <Center> {columnValue} / {columnTarget} </Center>
                                                </Flex>
                                                <Flex width={"60%"} alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                                                    <Flex flexDirection={'column'}>
                                                        <Flex alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                                                            <Center>{icon ?? ""}</Center>
                                                        </Flex>
                                                        <Flex alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                                                            <Center>{"⭐".repeat(columnStars)}</Center>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Center>
                                    </td>

                                })
                            }
                        </tr>
                    })}
                </MDBTableBody>
            </MDBTable>
        </Box>
    </div>
}
