import {AppEnvironment} from "../enums/AppEnvironment";
import {getCurrentAppEnvironment} from "../helpers/EnvironmentHelper";

const getBaseUrl = () => {
    switch (getCurrentAppEnvironment() || AppEnvironment.DEV) {
        case AppEnvironment.DEV:
            return 'http://localhost:6543'
            break;
        case AppEnvironment.QAL:
            return 'http://localhost:6543'
            break;
        case AppEnvironment.PROD:
            return 'https://api.sms-mozambique.adamyasolutions.com'
            break;
    }
}

const baseUrl = getBaseUrl();

const apiVersion = '/v1';

export const baseApiUrl = baseUrl + apiVersion;

export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    PATCH = "PATCH",
}

export const executeRequest = async (url : string, requestMethod : RequestMethod, body? : object, headers? : any, stringifyBody? : boolean) : Promise<any> => {

    try {
        const res = await fetch(url, {
            method: requestMethod.toString(),
            mode: 'cors',
            ...(body && {body: stringifyBody === false ? body : JSON.stringify(body)}),
            ...(headers && {headers: headers}),
        });

        if(res.status !== 200) {
            const resJson = await res.json();
            throw {
                err : `Error processing request`,
                errDetails : resJson,
                message : resJson?.message,
                errStatusCode : res.status
            }
        }
        return res.json();
    }
    catch (err) {
        throw err;
    }
}
