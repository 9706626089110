import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {slideDown} from './anim';
import './style.css';
import {
    Alert,
    AlertIcon,
    Badge,
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Progress,
    Select, Stack, Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    VStack
} from "@chakra-ui/react";
import {AddIcon, ChevronDownIcon, ChevronUpIcon, DeleteIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import {CustomOverlay} from "../../utilities/CustomOverlayModal";
import {getColumnHeaderName} from "../../utilities/getColumnHeaderName";
import {ColumnNameTooltip} from "../../utilities/getColumnNameTooltip";
import {AttachmentViewPopover} from "../AttachmentViewPopover";
import {useTranslation} from "react-i18next";
import Rating from "../Reliability";
import {ExamBasedFormEntry} from "../ExamBasedFormEntry";
import {PreviewTable} from "./PreviewTable";
import {getCompanySelectedFromLocalStorage, getIdTokenFromLocalStorage} from "../../utilities/LocalStorageUtil";
import {
    createIndicatorDataEntry,
    patchIndicatorDataEntryForIndicator, uploadAttachmentsForIndicatorDataEntry
} from "../../services/IndicatorDataEntryService";
import {sendIndicatorToFirstPhase, sendIndicatorToNextPhase} from "../../services/IndicatorService";
import {validatePhaseRecord} from "./IndicatorDataEntrySaveHelper";
import {PhaseNames} from "./PhaseNames";
import i18n from "i18next";
import {dateEntryRecordingStartDate, toastDuration} from "../../constants/ApplicationConstants";
import {checkIfUserHasIndicatorLevelPermissions} from "../../helpers/PermissionsHelper";
import {getPhaseDisplayNameFromIndex, getPhaseNameFromPhaseIndex} from "../../helpers/ApplicationHelper";
import {getAdditionalNoteLabel, getNoteLabel, getValueLabel} from "./DisplayValueHelper";
import {getEntryName, incrementQuarterSemesterYear} from "./TimeHelper";

const useExamBasedMode = true;

const CellDesigned = (props) => {
    const { isRef, value, notes, additionalNotes, attachmentPaths, notApplicable, notReported } = props;

    const { t, i18n } = useTranslation();

    return (
        <Flex className='cellDesigned' justifyContent={'center'}>
            {
                (notApplicable || notReported) ? (
                    <>
                        {
                            notApplicable
                                ? <Text><i>{t('NA')}</i></Text>
                                : notReported
                                    ? <Text><i>{t('NR')}</i></Text>
                                    : null
                        }
                    </>
                ) : (
                    <>
                        { isRef ? <Text color={'tomato'}>{t(value)}</Text> : <Text>{t(value)}</Text> }
                        { notes && notes !== '' ? <Tooltip label={notes} aria-label='A tooltip'>
                            <InfoOutlineIcon className={'notesTooltip'}  color={'blue'} />
                        </Tooltip> : null }
                        { additionalNotes && additionalNotes !== '' ? <Tooltip label={additionalNotes} aria-label=''>
                            <InfoOutlineIcon ml={2} className={'additionalNotesTooltip'}  color={'orange'} />
                        </Tooltip> : null }
                        { attachmentPaths.length ? <AttachmentViewPopover attachmentPaths={attachmentPaths} /> : null }
                    </>
                )
            }
        </Flex>
    );
}

export const AccordionTableBase = (props) => {
    const { t } = useTranslation();
    return <AccordionTableLocalizedBase t={t} {...props} />;
}

class AccordionTableLocalizedBase extends React.Component {
    state = { expanded: false }

    toggleExpander = (e) => {
        if (e.target.type === 'checkbox') return;

        if (!this.state.expanded) {
            this.setState(
                { expanded: true },
                () => {
                    if (this.refs.expanderBody) {
                        slideDown(this.refs.expanderBody);
                    }
                }
            );
        } else {
            this.setState({ expanded: false });
        }
    }

    render() {
        const { entry, currentPhaseIndex, referenceColumns, allEntries, indicatorDataType, t } = this.props;
        const { approvalProcessDataMap, name, recordingDate } = entry;

        const phases = Object.keys(approvalProcessDataMap).map(phaseKey => {
           return approvalProcessDataMap[phaseKey];
        });

        const currentPhase = phases.slice(-1)[0];

        return [
            <tr key="main" className={'expandableRow'} onClick={this.toggleExpander}>
                <td>
                    <HStack>
                        <Text><b>{entry.name}</b></Text>
                        {this.state.expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
                    </HStack>
                </td>
                {currentPhase && Object.keys(currentPhase).map(columnKey => {
                    // if this phase doesn't have value, then instead use the
                    // previous phase value only if the previous phase exists
                    let index = phases.length - 1;
                    let phaseToShow = phases[index];

                    while (index >= 0 && phaseToShow[columnKey].value === undefined) {
                        phaseToShow = phases[index];
                        index--;
                    }

                    if (columnKey === 'reliability') {
                        return <td>
                            <Rating
                                initialValue={phaseToShow[columnKey].value}
                                size={28}
                                viewOnly={true}
                                icon="star"
                                scale={3}
                                fillColor="gold"
                                strokeColor="grey"
                            />
                        </td>;
                    }
                    let found = false;
                    referenceColumns.forEach(referenceColumn => {
                        if (referenceColumn.name === columnKey) {
                            found = true;
                        }
                    })

                    return <td>
                        <CellDesigned
                        isRef={found}
                        value={phaseToShow[columnKey].value}
                        attachmentPaths={phaseToShow[columnKey].attachmentPaths}
                        notes={phaseToShow[columnKey].notes}
                        additionalNotes={phaseToShow[columnKey].additionalNotes}
                        notApplicable={phaseToShow[columnKey].notApplicable}
                        notReported={phaseToShow[columnKey].notReported}
                        />
                    </td>
                })}
            </tr>,
            this.state.expanded && phases.map((phase, idx) => {
                const phaseDescription = PhaseNames[('phase'+(idx+1)).toUpperCase()][i18n.language];
                return (
                    <tr className="expandable" key="tr-expander">
                        <td>{t("Phase")} {getPhaseDisplayNameFromIndex(idx)} [{phaseDescription}]</td>
                        {
                            Object.keys(phase).map(columnKey => {
                                if (columnKey === 'reliability') {
                                    return <td><Rating
                                        initialValue={phase[columnKey].value}
                                        viewOnly={true}
                                        size={28}
                                        icon="star"
                                        scale={3}
                                        fillColor="gold"
                                        strokeColor="grey"
                                    /></td>;
                                }let found = false;
                                referenceColumns.forEach(referenceColumn => {
                                    if (referenceColumn.name === columnKey) {
                                        found = true;
                                    }
                                })
                                return <td><CellDesigned
                                    isRef={found}
                                    value={phase[columnKey].value}
                                    attachmentPaths={phase[columnKey].attachmentPaths}
                                    notes={phase[columnKey].notes}
                                    additionalNotes={phase[columnKey].additionalNotes}
                                    notApplicable={phase[columnKey].notApplicable}
                                    notReported={phase[columnKey].notReported}
                                />
                                </td>
                            })
                        }
                    </tr>);
            })

        ];
    }
}

export const FormCell = (props) => {
    const { columns, indicatorDataType, columnKey, phaseKeyToSave, setPhaseRecord, phaseRecord, company, indicator } = props;
    const column = columns.find(c => c.name === columnKey);
    const columnValue = phaseRecord[columnKey];
    const [valueValue, setValueValue] = React.useState(columnValue ? columnValue.value : undefined);
    const [notesValue, setNotesValue] = React.useState(columnValue ? columnValue.notes : undefined);
    const [additionalNotesValue, setAdditionalNotesValue] = React.useState(columnValue ? columnValue.additionalNotes : undefined);
    const selectedFiles= React.useRef([]);
    const isFilePicked = React.useRef(false);
    const [notApplicableDisabled, setNotApplicableDisabled] = React.useState(phaseRecord[columnKey]?.notApplicable);
    const [notReportedDisabled, setNotReportedDisabled] = React.useState(phaseRecord[columnKey]?.notReported);
    const [uploadInProgress, setUploadInProgress] = React.useState(false);
    const toast = useToast();

    const phaseSequence = indicatorDataType && indicator.indicatorDataTypeStructureList[indicatorDataType]?.phaseSequence;
    const currentPhaseIndex = indicatorDataType && indicator.indicatorDataTypeStructureList[indicatorDataType]?.currentPhaseIndex;

    const fileChangeHandler = (event) => {
        selectedFiles.current = event.target.files;
        isFilePicked.current = true;
        isFilePicked.current = true;
        uploadFile();
    };

    const uploadFile = () => {
        setUploadInProgress(true);
        const idToken = getIdTokenFromLocalStorage();
        const formData = new FormData();

        for (let i = 0; i < selectedFiles.current.length; i++) {
            formData.append(selectedFiles.current[i].name, selectedFiles.current[i])
        }

        if (isFilePicked.current && company && indicator && idToken) {
            uploadAttachmentsForIndicatorDataEntry(formData)
                .then((buckets) => {
                    if (buckets.length) {
                        const columnEntry = phaseRecord[columnKey];
                        setPhaseRecord({
                            ...phaseRecord,
                            ...(columnEntry ? { [columnKey]: Object.assign(columnEntry, {attachmentPaths: [...(columnEntry?.attachmentPaths ?? []), ...buckets]}) } : { [columnKey]: { value: valueValue, notes: notesValue, attachmentPaths: buckets }})
                        });
                    }
                    toast({
                        title: `${t('Successfully uploaded file')}!`,
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setUploadInProgress(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    toast({
                        title: t('Failed to upload file'),
                        description: t(error.toString()),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setUploadInProgress(false);
                });
        }
    }

    const removeFile = (key) => {
        const columnEntry = phaseRecord[columnKey];
        setPhaseRecord({
            ...phaseRecord,
            ...{[columnKey]: Object.assign(columnEntry, {attachmentPaths: columnEntry.attachmentPaths.filter(path => path.Key !== key)})}
        });
    }

    const handleChange = (e, type) => {
        const val = e.target.value;
        const columnEntry = phaseRecord[columnKey];
        if (type === 'value') {
            setValueValue(val);
            columnEntry.value = val;
            setPhaseRecord({
                ...phaseRecord,
                ...(columnEntry ? { [columnKey]: columnEntry } : { [columnKey]: { value: val, notes: notesValue, additionalNotes: additionalNotesValue }})
            })
        } else if (type === 'notes') {
            setNotesValue(val);
            columnEntry.notes = val;
            setPhaseRecord({
                ...phaseRecord,
                ...(columnEntry ? { [columnKey]: { ...columnEntry, notes: val }} : { [columnKey]: { notes: val, value: valueValue, additionalNotes: additionalNotesValue }})
            })
        } else {
            setAdditionalNotesValue(val);
            columnEntry.additionalNotes = val;
            setPhaseRecord({
                ...phaseRecord,
                ...(columnEntry ? { [columnKey]: { ...columnEntry, notes: val }} : { [columnKey]: { notes: notesValue, value: valueValue, additionalNotes: val }})
            })
        }
    }

    const {t} = useTranslation();

    const InputWithDataValidation = (dataValidation, preValue) => {
        if (dataValidation?.columnDataType === "LIST") {
            return <Select onChange={(e) => handleChange(e, 'value')}>
                <option>{t('Select')}</option>
                {
                    dataValidation?.values?.map((value, idx) => {
                        return <option selected={preValue === value} key={"LIST" + value + idx} value={value}>{t(value)}</option>
                    })
                }
            </Select>;
        } else if (dataValidation?.columnDataType === "NUMBER") {
            return <NumberInput
                defaultValue={preValue?.length ? preValue : dataValidation?.gte}
                max={dataValidation?.lte}
                min={dataValidation?.gte}
                onChange={(e) => handleChange({ target: { value: e }}, 'value')}
                keepWithinRange={false}
                clampValueOnBlur={true}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>;
        }
    }

    return (
        <td width={'100%'}>
            {
                (
                    (column.notApplicableSupported || []).includes(phaseKeyToSave)
                    ||
                    (column.notReportedSupported || []).includes(phaseKeyToSave)
                ) && (
                    <Flex justifyContent={"flex-end"}>
                        {
                            (column.notApplicableSupported || []).includes(phaseKeyToSave) && (
                                <Stack ml={3} mr={3} align='center' direction='row'>
                                    <Switch
                                        isChecked={phaseRecord[columnKey].notApplicable}
                                        size='md'
                                        onChange={(e) => {
                                            setNotApplicableDisabled(e.target.checked)
                                            setNotReportedDisabled(false)

                                            const columnEntry = phaseRecord[columnKey];

                                            if (!notApplicableDisabled) {
                                                columnEntry.value = null;
                                                columnEntry.notes = null;
                                                columnEntry.additionalNotes = null;
                                                columnEntry.notApplicable = true;
                                                columnEntry.notReported = false;
                                            } else {
                                                columnEntry.value = valueValue;
                                                columnEntry.notes = notesValue;
                                                columnEntry.additionalNotes = additionalNotesValue;
                                                columnEntry.notApplicable = null;
                                                columnEntry.notReported = null;
                                            }

                                            setPhaseRecord({
                                                ...phaseRecord,
                                                ...(
                                                    columnEntry
                                                        ? {[columnKey]: columnEntry}
                                                        : {
                                                            [columnKey]: {
                                                                value: columnEntry.value,
                                                                notes: columnEntry.notes,
                                                                additionalNotes: columnEntry.additionalNotes,
                                                                notApplicable: columnEntry.notApplicable,
                                                                notReported: columnEntry.notReported
                                                            }
                                                        }
                                                )
                                            })

                                        }}/>
                                    <Text>{
                                        (column.notApplicableSupported || []).includes(phaseKeyToSave) ? t('Not Applicable') : null
                                    }</Text>
                                </Stack>
                            )
                        }
                        {
                            (column.notReportedSupported || []).includes(phaseKeyToSave) && (
                                <Stack ml={3} mr={3} align='center' direction='row'>
                                    <Switch
                                        isChecked={phaseRecord[columnKey].notReported}
                                        size='md'
                                        onChange={(e) => {
                                            setNotReportedDisabled(e.target.checked)
                                            setNotApplicableDisabled(false)

                                            const columnEntry = phaseRecord[columnKey];

                                            if (!notReportedDisabled) {
                                                columnEntry.value = null;
                                                columnEntry.notes = null;
                                                columnEntry.additionalNotes = null;
                                                columnEntry.notApplicable = false;
                                                columnEntry.notReported = true;
                                            } else {
                                                columnEntry.value = valueValue;
                                                columnEntry.notes = notesValue;
                                                columnEntry.additionalNotes = additionalNotesValue;
                                                columnEntry.notApplicable = null;
                                                columnEntry.notReported = null;
                                            }

                                            setPhaseRecord({
                                                ...phaseRecord,
                                                ...(
                                                    columnEntry
                                                        ? {[columnKey]: columnEntry}
                                                        : {
                                                            [columnKey]: {
                                                                value: columnEntry.value,
                                                                notes: columnEntry.notes,
                                                                additionalNotes: columnEntry.additionalNotes,
                                                                notApplicable: columnEntry.notApplicable,
                                                                notReported: columnEntry.notReported
                                                            }
                                                        }
                                                )
                                            })

                                        }}/>
                                    <Text>{
                                        (column.notReportedSupported || []).includes(phaseKeyToSave) ? t('Not Reported') : null
                                    }</Text>
                                </Stack>
                            )
                        }
                    </Flex>
                )
            }
            {
                columnKey === 'reliability' ?
                    <Rating
                        handleRatingChange={(rating) => handleChange({target: {value: rating}}, 'value')}
                        initialValue={columnValue?.value}
                        size={28}
                        icon="star"
                        scale={3}
                        fillColor="gold"
                        strokeColor="grey"
                    />
                    : <Box width={'100%'}>
                        { column.dataValidation ?
                            <FormControl hidden={!phaseSequence[currentPhaseIndex]?.supportedDataInputs?.value} isDisabled={notApplicableDisabled || notReportedDisabled}>
                                <FormLabel htmlFor='value'>{getValueLabel(currentPhaseIndex, indicatorDataType, t)} {column?.unit[i18n.language] ? `[${column?.unit[i18n.language]}]` : ''}</FormLabel>
                                {
                                    InputWithDataValidation(column.dataValidation, valueValue)
                                }
                            </FormControl> :
                            <FormControl hidden={!phaseSequence[currentPhaseIndex]?.supportedDataInputs?.value} isDisabled={notApplicableDisabled || notReportedDisabled}>
                                <FormLabel htmlFor='value'>{getValueLabel(currentPhaseIndex)}</FormLabel>
                                <Input id='value' type='text' value={valueValue} onChange={(e) => handleChange(e, 'value')}/>
                            </FormControl>
                        }
                        <FormControl hidden={!phaseSequence[currentPhaseIndex]?.supportedDataInputs?.notes} isDisabled={notApplicableDisabled || notReportedDisabled}>
                            <FormLabel htmlFor='notes'>{getNoteLabel(currentPhaseIndex, indicatorDataType, t)}</FormLabel>
                            <Input id='notes' type='text' value={notesValue} onChange={(e) => handleChange(e, 'notes')}/>
                        </FormControl>
                        <FormControl hidden={!phaseSequence[currentPhaseIndex]?.supportedDataInputs?.additionalNotes} isDisabled={notApplicableDisabled || notReportedDisabled}>
                            <FormLabel htmlFor='additionalNotes'>{getAdditionalNoteLabel(currentPhaseIndex, indicatorDataType, t)}</FormLabel>
                            <Input id='additionalNotes' type='text' value={additionalNotesValue} onChange={(e) => handleChange(e, 'additionalNotes')}/>
                        </FormControl>
                        <VStack mt={5} hidden={!phaseSequence[currentPhaseIndex]?.supportedDataInputs?.attachmentPaths}>
                            { uploadInProgress ? <Progress colorScheme='red' width={'100%'} height='32px' isIndeterminate /> : columnValue?.attachmentPaths?.length ?
                                <Alert className={'file-upload-stats'} status='success' variant='top-accent'>
                                    <HStack>
                                        <AlertIcon />
                                        <Text>{columnValue?.attachmentPaths?.length + ' ' + t('file(s) uploaded')}</Text>
                                        <div className="image-upload">
                                            <label htmlFor={"multiple-file-input"+columnKey}>
                                                <AddIcon ml={2} />
                                            </label>

                                            <input id={"multiple-file-input"+columnKey} className={'fileUpload'} type="file" name="file" onChange={fileChangeHandler} multiple />
                                        </div>
                                    </HStack>
                                    <Box maxW={200} className={'list-of-files'}>
                                        <ul style={{listStyle: "square outside"}}>
                                            {columnValue?.attachmentPaths?.map((path, idx) => <li key={path.key + idx}>
                                                <Box className={'file-details'}>
                                                    <Text isTruncated><a target={"_blank"} href={path.Location}>{path.Key}</a></Text>
                                                    <DeleteIcon className={'delete-file'} onClick={() => removeFile(path.Key)}/>
                                                </Box>
                                            </li>)}
                                        </ul>
                                    </Box>
                                </Alert> :
                                <Alert status='warning' variant='top-accent'>
                                    <AlertIcon />
                                    <Text>{t('No files uploaded')}</Text>
                                    <div className="image-upload">
                                        <label htmlFor={"multiple-file-input"+columnKey}>
                                            <AddIcon ml={2} />
                                        </label>

                                        <input disabled={notApplicableDisabled || notReportedDisabled} id={"multiple-file-input"+columnKey} className={'fileUpload'} type="file" name="file" onChange={fileChangeHandler} multiple />
                                    </div>
                                </Alert>
                            }
                        </VStack>
                    </Box>
            }
        </td>
    );
};

const RecordRow = (props) => {
    const { referenceColumns, currentPhase, phaseKeyToSave, index, columns, setPhaseRecord, indicatorDataType, phaseRecord, company, indicator } = props;
    const {t, i18n} = useTranslation();
    const phaseSequence = indicator.indicatorDataTypeStructureList[indicatorDataType]?.phaseSequence;

    return (
        <tr>
            <td>{phaseSequence[index]?.displayName[i18n.language ?? 'en']}</td>
            {
                currentPhase ? Object.keys(currentPhase).map(columnKey => {
                    let found = false;
                    referenceColumns.forEach(referenceColumn => {
                        if (referenceColumn.name === columnKey) {
                            found = true;
                        }
                    });

                    if (found) {
                        return <td><Text color={'red'}>{t("This field is being referenced.")}</Text></td>;
                    }

                    const columnValue = currentPhase[columnKey];
                    return
                        <FormCell
                            indicatorDataType={indicatorDataType}
                            columns={columns}
                            company={company}
                            indicator={indicator}
                            phaseRecord={phaseRecord}
                            columnValue={columnValue}
                            columnKey={columnKey}
                            phaseKeyToSave={phaseKeyToSave}
                            setPhaseRecord={setPhaseRecord}
                        />;
                }) :
                columns?.map(columnKey => {
                    let found = false;
                    referenceColumns.forEach(referenceColumn => {
                        if (referenceColumn.name === columnKey.name) {
                            found = true;
                        }
                    });

                    if (found) {
                        return <td><Text color={'red'}>{t('This field is being referenced.')}</Text></td>;
                    }
                    return <FormCell
                        indicatorDataType={indicatorDataType}
                        columns={columns}
                        company={company}
                        indicator={indicator}
                        phaseRecord={phaseRecord}
                        columnValue={undefined}
                        columnKey={columnKey.name}
                        phaseKeyToSave={phaseKeyToSave}
                        setPhaseRecord={setPhaseRecord}
                    />;
                })
            }
        </tr>
    );
};

const getInitialState = (columns) => {
    let phaseRecord = {};
    columns.forEach(column => {
        phaseRecord[column.name] = { value: undefined,  notes: undefined}
    });
    return phaseRecord;
}

const PhaseTable = forwardRef((props, ref) => {
    const { showPreviewTable, setPreviewTableVisibility, referenceColumns, setRefreshEntries, onClose, entry, columns, modalActionType, company, idToken, indicator, indicatorDataType, currentPhaseIndex } = props;
    const [phaseRecord, setPhaseRecord] = React.useState(getInitialState(columns));
    const [dataEntryId, setDataEntryId] = React.useState();
    const toast = useToast();
    const { t } = useTranslation();
    let phases = [];

    if (entry) {
        const { approvalProcessDataMap, name } = entry;
        phases = Object.keys(approvalProcessDataMap).map((phaseKey, idx) => {
            return approvalProcessDataMap[phaseKey];
        });
    }

    React.useEffect(() => {
    }, [phaseRecord]);

    React.useEffect(() => {
        if (modalActionType === "EDIT") {
            const currentPhase = phases?.slice(-1)[0];
            setPhaseRecord(currentPhase);
        }
    },[phases]);

    const currentPhase = modalActionType === "EDIT" ? phases?.slice(-1)[0] : null;
    const viewPhases = modalActionType === "RECORD" ? phases : phases.slice(0, -1);
    const phaseKeyToSave = modalActionType === "RECORD" ? 'phase' + getPhaseDisplayNameFromIndex(phases.length) : 'phase' + getPhaseDisplayNameFromIndex(phases.length - 1);
    const finalSave = (dataEntryId) => {
        if (!dataEntryId && company && idToken && indicator && phaseRecord) {
            createIndicatorDataEntry(indicator?.indicatorNumber, {
                indicatorDataType : indicatorDataType,
                fieldsValues : [],
                securityGroupsIds : [],
                approvalProcessDataMap : {
                    phase1 :
                        {
                            ...phaseRecord
                        }
                },
            }).then(indicatorDataEntryResponse => {
                toast({
                    title: t('Phase recorded'),
                    description: '',
                    status: 'success',
                    duration: toastDuration,
                    isClosable: true,
                })
            }).catch(err => {
                toast({
                    title: t('Failed to record phase'),
                    description: t(err?.statusText),
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true,
                })
            }).finally(() => {
                setRefreshEntries(true);
                onClose();
            })
        }
        if (company && idToken && indicator && dataEntryId && phaseRecord) {
            const { approvalProcessDataMap, name } = entry;

            patchIndicatorDataEntryForIndicator(dataEntryId, indicator?.indicatorNumber, {
                approvalProcessDataMap : {
                    ...approvalProcessDataMap,
                    [phaseKeyToSave]: {
                        ...phaseRecord
                    }
                }
            })
                .then((indicatorDataEntryResponse) => {
                    toast({
                        title: t('Phase recorded'),
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    })
                })
                .catch(err => {
                    toast({
                        title: t('Failed to record phase'),
                        description: t('Try again later'),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setRefreshEntries(true);
                    onClose();
                })
        }
    }

    useImperativeHandle(ref, () => ({
         savePhaseRecord(entryId) {
             if(validatePhaseRecord(phaseRecord, indicatorDataType, indicator, phaseKeyToSave)) {
                 setDataEntryId(entryId);
                 setPreviewTableVisibility(true);
             }
             else {
                 toast({
                     title: t('Invalid input'),
                     description: t('Please enter all values'),
                     status: 'error',
                     duration: toastDuration,
                     isClosable: true,
                 })
             }
        },
        getPreviewTable() {
            return showPreviewTable;
        }
    }))


    return showPreviewTable ?
        <PreviewTable
            currentPhaseIndex={currentPhaseIndex}
            reject={() => {
                setPreviewTableVisibility(false)
            }}
            phaseRecord={phaseRecord}
            finalSave={() => finalSave(dataEntryId)}
            indicatorDataType={indicatorDataType}
            columns={columns}
        /> : (
        <>
            {/*<Button colorScheme={"blue"} mt={2} mb={!useExamBasedMode ? 2 : 0} onClick={() => savePhaseRecord(entry?._id)}>{t("Save")}</Button>*/}
            {!useExamBasedMode ? <Box className={'table-responsive'}><MDBTable bordered>
                    <MDBTableHead>
                        <tr className={'table-dark'}>
                            <th className="uk-table-shrink">{t("Phase")}</th>
                            {columns?.map((column) => <th>{column}</th>)}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {viewPhases.map((phase, idx) => {
                            return (
                                <tr>
                                    <td>{t("Phase")} {getPhaseDisplayNameFromIndex(idx)}</td>
                                    {
                                        Object.keys(phase).map(columnKey => {
                                            if (columnKey === 'reliability') {
                                                return <td>
                                                    <Rating
                                                        initialValue={phase[columnKey].value}
                                                        viewOnly={true}
                                                        size={28}
                                                        icon="star"
                                                        scale={3}
                                                        fillColor="gold"
                                                        strokeColor="grey"
                                                    />
                                                </td>;
                                            }
                                            return <td>{t(phase[columnKey].value)}</td>
                                        })
                                    }
                                </tr>
                            );
                        })}
                        <RecordRow
                            referenceColumns={referenceColumns}
                            company={company}
                            indicator={indicator}
                            phaseRecord={phaseRecord}
                            setPhaseRecord={setPhaseRecord}
                            currentPhase={currentPhase}
                            phaseKeyToSave={phaseKeyToSave}
                            columns={columns}
                            indicatorDataType={indicatorDataType}
                            index={currentPhase ? phases.length - 1 : phases.length}
                        />
                    </MDBTableBody>
                </MDBTable></Box> :
                <ExamBasedFormEntry
                    viewPhases={viewPhases}
                    referenceColumns={referenceColumns}
                    company={company}
                    indicator={indicator}
                    phaseRecord={phaseRecord}
                    phaseKeyToSave={phaseKeyToSave}
                    setPhaseRecord={setPhaseRecord}
                    currentPhase={currentPhase}
                    columns={columns}
                    indicatorDataType={indicatorDataType}
                    index={currentPhase ? phases.length - 1 : phases.length}
                />
            }
        </>
    );
});

const GetPhaseButtons = (props) => {
    const { t, i18n } = useTranslation();

    const {
        indicatorDataType,
        indicator,
        entries,
        sendToFirstPhase,
        currentPhaseIndex,
        setModalAction,
        onOpen,
        setModalActionType,
        sendPhase
    } = props;

    let indicatorPhaseType = 'RECORD';

    const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose } = useDisclosure();

    const recordPhase = () => {
        onOpen();
        setModalAction(t("Record Phase") + " " + (getPhaseDisplayNameFromIndex(currentPhaseIndex)) + modalActionPhaseDescription);
        setModalActionType('RECORD');
    };

    const currentQuarter = entries.slice(-1)[0];

    // TODO: check if last record (nth record) has 6 phases
    // if yes, then increment quarter/semester or year
    // here the word quarter will be used to represent semester and year also

    const noOfPhases = currentQuarter && Object.keys(currentQuarter.approvalProcessDataMap).length;

    if (currentPhaseIndex !== 0 && noOfPhases === 7) {
        // increment quarter / semester / year
        sendToFirstPhase();
    }

    // set record phase 1 if currentQuarter doesn't match current time
    // TODO: Current logic removed
    // if (currentQuarter && getEntryName(currentQuarter.recordingDate, currentQuarter.indicatorDataType) !== getEntryName(undefined, currentQuarter.indicatorDataType)) {
    //     // sendToFirstPhase();
    // }

    if (currentQuarter) {
        const { approvalProcessDataMap } = currentQuarter;
        if (approvalProcessDataMap.hasOwnProperty('phase' + (getPhaseDisplayNameFromIndex(currentPhaseIndex)))) {
            indicatorPhaseType = 'EDIT';
        } else {
            indicatorPhaseType = 'RECORD';
        }
    }

    if (currentPhaseIndex === 0 && noOfPhases === 7) {
        indicatorPhaseType = 'RECORD';
    }

    const modalActionPhaseDescription = " [" + PhaseNames[('phase'+parseInt(currentPhaseIndex+1)).toUpperCase()][i18n.language] + "]";

    const editPhase = () => {
        onOpen();
        setModalAction(t("Edit Phase") + ' ' + (getPhaseDisplayNameFromIndex(currentPhaseIndex)) + modalActionPhaseDescription);
        setModalActionType('EDIT');
    }

    if (indicatorPhaseType === "RECORD" && currentPhaseIndex === 7) {
        return null;
    }

    const getPermissionName = (action) => {
        return `${action}${getPhaseNameFromPhaseIndex(currentPhaseIndex)}_${indicatorDataType}`
    }

    return indicatorPhaseType === "RECORD" && checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, getPermissionName('RECORD_PHASE'))
        ? (
            <Button colorScheme={'orange'} onClick={recordPhase}>{t("Record Phase")} {getPhaseDisplayNameFromIndex(currentPhaseIndex)}</Button>
        )
        : (
            <>
                <Modal isOpen={modalIsOpen} onClose={modalOnClose} scrollBehavior={"inside"} isCentered>
                    <CustomOverlay />
                    <ModalContent className={'confirmPhaseSendModal'}>
                        <ModalBody >
                            <Text mt={1} textAlign="center">{
                                currentPhaseIndex === 6 ? `${t("Finalise data")}?` : `${t("Send to next phase")}?`
                            }</Text>
                            <HStack mt={5} mb={1} w={"100%"} justifyContent={'space-between'}>
                                <Button colorScheme='red' onClick={modalOnClose}>
                                    {t("No")}
                                </Button>
                                <Button colorScheme='green' onClick={() => { sendPhase(); modalOnClose(); }}>
                                    {t("Yes")}
                                </Button>
                            </HStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                {
                    checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, getPermissionName('EDIT_PHASE'))
                        ? <Button colorScheme={'red'} onClick={editPhase}>{t("Edit Phase")} {getPhaseDisplayNameFromIndex(currentPhaseIndex)}</Button>
                        : null
                }
                {
                    checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, getPermissionName('SEND_PHASE'))
                        ? <Button ml={5} colorScheme={'green'} onClick={modalOnOpen}>{t("Send Phase")} {getPhaseDisplayNameFromIndex(currentPhaseIndex)}</Button>
                        : null
                }
            </>
        );
};

export const AccordionTable = (props) => {
    const {
        allEntries,
        setRefreshEntries,
        indicator,
        setIndicator,
        entries,
        columns,
        currentPhaseIndex,
        indicatorDataType
    } = props;

    const isLoading = entries === null;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalAction, setModalAction] = React.useState('');
    const [modalActionType, setModalActionType] = React.useState('RECORD');
    const idToken = getIdTokenFromLocalStorage();
    const [company, setCompany] = React.useState();
    const phaseTableRef = useRef(null);
    const {t} = useTranslation();
    const toast = useToast();
    const [showPreviewTable, setPreviewTableVisibility] = React.useState(false);

    const sendToFirstPhase = () => {
        if (company && idToken && indicator && indicatorDataType) {
            sendIndicatorToFirstPhase(indicator?._id, indicatorDataType)
                .then(indicatorResponse => {
                    toast({
                        title: t('Sent to next phase successfully'),
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    })
                    setIndicator(indicatorResponse)
                })
                .catch(err => {
                    toast({
                        title: t('Unexpected error occurred'),
                        description: '',
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    })
                })
                .finally(() => setRefreshEntries(true))
        }
    }

    const sendPhase = () => {
        // make API call to increment current phase index
        if (company && idToken && indicator && indicatorDataType) {

            sendIndicatorToNextPhase(indicator?._id, indicatorDataType)
                .then(indicatorResponse => {
                    toast({
                        title: t('Sent to next phase successfully'),
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    })
                    setIndicator(indicatorResponse)
                })
                .catch(err => {
                    toast({
                        title: t('Unexpected error occurred'),
                        description: '',
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    })
                })
                .finally(() => setRefreshEntries(true))
        }
    };

    React.useEffect(() => {
        const company = getCompanySelectedFromLocalStorage();
        if(company) {
            setCompany(company);
        }
    }, []);

    const referenceColumns = columns?.filter(column => column.indicatorDataType !== indicatorDataType);

    const getPhaseEntry = () => {
        if (currentPhaseIndex === 0 && Object.keys(entries?.slice(-1)[0]?.approvalProcessDataMap ?? {}).length === 7) {
            return undefined;
        }
        return entries?.slice(-1)[0];
    }

    const entry = getPhaseEntry();

    const modalOnClose = () => {
        setPreviewTableVisibility(false);
        onClose();
    };

    return (
        <>
            <Box maxH={400} mb={15} className={'table-responsive'}>
                <MDBTable bordered striped borderColor="dark">
                    <MDBTableHead>
                        <tr className='table-dark'>
                            <th className="uk-table-shrink">{getColumnHeaderName(indicatorDataType)}</th>
                            { columns?.map((column) => {
                                return <th>{<ColumnNameTooltip columnName={column.displayName} columnDescription={column.description}/>}</th>
                            }) }
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody >
                        {
                            isLoading
                                ? <tr><td colSpan={6} className="uk-text-center"><em className="uk-text-muted">{t('Loading')}...</em></td></tr>
                                : entries?.map((entry, index) =>
                                    <AccordionTableBase
                                        key={index}
                                        referenceColumns={referenceColumns}
                                        indicatorDataType={indicatorDataType}
                                        allEntries={allEntries}
                                        index={index + 1}
                                        entry={entry}
                                        currentPhaseIndex={currentPhaseIndex}/>
                                )
                        }
                    </MDBTableBody >
                </MDBTable>
            </Box>
            <Modal size={useExamBasedMode ? '3xl' : 'full'} isOpen={isOpen} onClose={modalOnClose} scrollBehavior={"inside"} isCentered>
                <CustomOverlay />
                <ModalContent className={'modalPhaseRecordContent'}>
                    <ModalHeader>
                        <Text>{modalAction} - {
                            entry
                                ? getEntryName(entry.recordingDate, entry.indicatorDataType)
                                : entries && entries.length === 0
                                    ? getEntryName(new Date(dateEntryRecordingStartDate), indicatorDataType)
                                    : getEntryName(
                                        incrementQuarterSemesterYear(
                                            new Date(Math.max(...entries.map(entry => new Date(entry.recordingDate)))),
                                            indicatorDataType),
                                        indicatorDataType
                                    )
                        }</Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box w={"100%"}>
                            <PhaseTable
                                ref={phaseTableRef}
                                referenceColumns={referenceColumns}
                                setRefreshEntries={setRefreshEntries}
                                entry={entry}
                                company={company}
                                idToken={idToken}
                                showPreviewTable={showPreviewTable}
                                setPreviewTableVisibility={(val) => setPreviewTableVisibility(val)}
                                indicator={indicator}
                                indicatorDataType={indicatorDataType}
                                columns={columns}
                                onClose={modalOnClose}
                                modalActionType={modalActionType}
                                currentPhaseIndex={currentPhaseIndex}
                            />
                        </Box>
                    </ModalBody>
                    { showPreviewTable ? null :
                        <ModalFooter bg={"#ebebeb"} borderBottomRadius={6}>
                            <Button
                                colorScheme={"blue"}
                                mt={2}
                                onClick={
                                    () => phaseTableRef?.current?.savePhaseRecord(entry?._id)
                                }
                            >
                                {
                                    t("Save")
                                }
                            </Button>
                        </ModalFooter>
                    }
                </ModalContent>
            </Modal>
            <Flex justifyContent={'end'}>
                <GetPhaseButtons
                    indicatorDataType={indicatorDataType}
                    indicator={indicator}
                    entries={entries}
                    currentPhaseIndex={currentPhaseIndex}
                    setModalAction={setModalAction}
                    onOpen={onOpen}
                    setModalActionType={setModalActionType}
                    sendToFirstPhase={sendToFirstPhase}
                    sendPhase={sendPhase} />
            </Flex>
        </>
    );
}
