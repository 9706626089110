import * as React from "react";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../i18n/SelectedLanguage";
import {QuarterPicker} from "./durationPicker/QuarterPicker";
import {Alert, Center, Flex, Select, VStack} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {Report} from "./enums/Report";
import {SidebarWithHeader} from "../SidebarWithHeader";
import {Report4_Reporting} from "./reporting/Report4_Reporting";
import {Report5_Reporting} from "./reporting/Report5_Reporting";
import {getStartRecordingDateForDate} from "../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../enums/IndicatorDataType";
import {getCurrentDateMoment, getStartOfQuarter, getStartOfSemester, getStartOfYear} from "../../helpers/DateHelper";
import {YearPicker} from "./durationPicker/YearPicker";
import {checkCompanyStorageVariablesElseNavigate} from "../../helpers/NavigationHelper";
import {checkIfUserHasCompanyLevelPermissions} from "../../helpers/PermissionsHelper";
import {CompanyLevelActions} from "../../enums/featureActions/CompanyLevelActions";
import {Report2_Complementary} from "./complementary/Report2_Complementary";
import {SemesterPicker} from "./durationPicker/SemesterPicker";
import {Report2_USP} from "./usp/Report2_USP";

export const ViewMunicipalityReports = (
    props: {

    }
): JSX.Element => {

    const [selectedReport, setSelectedReport] = React.useState<Report>();

    const [reportDuration, setReportDuration] = React.useState<moment.Moment>(moment(getStartRecordingDateForDate(getCurrentDateMoment().toDate(), IndicatorDataType.AURA_REPORTING)).subtract(1, 'quarter'));

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    React.useEffect(() => checkCompanyStorageVariablesElseNavigate(navigate), []);

    return (
        <>
            <SidebarWithHeader isNonCompanySpecificPage={false}>
                <Center>
                    <Flex flexDirection={"column"} w={"100%"}>
                        {
                            checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.VIEW_MUNICIPALITY_REPORT) ? (<>
                                <Flex>
                                    <Select
                                        value={selectedReport}
                                        borderWidth={2}
                                        borderColor='black'
                                        onChange={(e : any) => {
                                            const reportSelected : Report = e.target.value;
                                            setSelectedReport(reportSelected)

                                            switch (reportSelected) {
                                                case Report.REPORT_4_REPORTING:
                                                    setReportDuration(moment(getStartOfQuarter(getCurrentDateMoment().toDate())).subtract(1, 'quarter'))
                                                    break;
                                                case Report.REPORT_5_REPORTING:
                                                    setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                                    break;
                                                case Report.REPORT_2_COMPLEMENTARY:
                                                    setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                                    break;
                                                case Report.REPORT_2_USP:
                                                    setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                                    break;
                                            }
                                        }}
                                        width={"-webkit-fill-available"}
                                        placeholder={t('Select Municipality Report')}
                                        m={1}
                                    >
                                        <option value={Report.REPORT_4_REPORTING}>{t('AURA Reporting') } - {t('Quarterly Achievements of all indicators')}</option>
                                        <option value={Report.REPORT_5_REPORTING}>{t('AURA Reporting') } - {t('Annual Achievements of all indicators')}</option>
                                        <option value={Report.REPORT_2_COMPLEMENTARY}>{t('AURA Complementary') } - {t('Complementary data for all indicators')}</option>
                                        <option value={Report.REPORT_2_USP}>{t('DNAAS USP') } - {t('USP data for all indicators')}</option>
                                    </Select>

                                    {
                                        selectedReport ? (
                                            selectedReport === Report.REPORT_4_REPORTING
                                                ? (
                                                    <Center m={1}>
                                                        <QuarterPicker
                                                            selectedDuration={reportDuration}
                                                            setSelectedDuration={setReportDuration}
                                                        />
                                                    </Center>
                                                )
                                                : selectedReport === Report.REPORT_5_REPORTING
                                                    ? (
                                                        <Center m={1}>
                                                            <YearPicker
                                                                selectedDuration={reportDuration}
                                                                setSelectedDuration={setReportDuration}
                                                            />
                                                        </Center>
                                                    ) : selectedReport === Report.REPORT_2_COMPLEMENTARY
                                                        ? (
                                                            <Center m={1}>
                                                                <YearPicker
                                                                    selectedDuration={reportDuration}
                                                                    setSelectedDuration={setReportDuration}
                                                                />
                                                            </Center>
                                                        ) : selectedReport === Report.REPORT_2_USP
                                                            ? (
                                                                <Center m={1}>
                                                                    <YearPicker
                                                                        selectedDuration={reportDuration}
                                                                        setSelectedDuration={setReportDuration}
                                                                    />
                                                                </Center>
                                                            ) : null
                                        ) : null
                                    }

                                </Flex>
                                {
                                    selectedReport ? (
                                        selectedReport === Report.REPORT_4_REPORTING
                                            ? (
                                                <Report4_Reporting reportDuration={reportDuration} />
                                            )
                                            : selectedReport === Report.REPORT_5_REPORTING
                                                ? (
                                                    <Report5_Reporting reportDuration={reportDuration} />
                                                ) : selectedReport === Report.REPORT_2_COMPLEMENTARY
                                                    ? (
                                                        <Report2_Complementary reportDuration={reportDuration} />
                                                    ) :  selectedReport === Report.REPORT_2_USP
                                                        ? (
                                                            <Report2_USP reportDuration={reportDuration} />
                                                        ) : null
                                    ) : (
                                        <VStack h={"80vh"} justify={'center'}>
                                            <Alert w={"40%"}>{t("Please select a municipality report to view")}!</Alert>
                                        </VStack>
                                    )
                                }
                            </>) : (<>
                                <VStack h={"80vh"} justify={'center'}>
                                    <Alert w={"40%"}>{t("Insufficient permissions")}!</Alert>
                                </VStack>
                            </>)
                        }
                    </Flex>
                </Center>
            </SidebarWithHeader>
        </>
    );
}
