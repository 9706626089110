import {IndicatorDataType} from "../../enums/IndicatorDataType";
import moment from 'moment';
import {getQuarter, getSemester, getYear} from "../../helpers/DateHelper";

export const incrementQuarterSemesterYear = (date: Date, indicatorDataType: IndicatorDataType): Date => {
    switch (indicatorDataType) {
        case IndicatorDataType.AURA_COMPLEMENTARY:
            return moment(date).add(1, 'year').toDate();
        case IndicatorDataType.AURA_REPORTING:
            return moment(date).add(1, 'quarter').toDate();
        case IndicatorDataType.DNAAS_USP:
            return moment(date).add(1, 'year').toDate();
    }
};

export const getEntryName = (recordingDate: Date, indicatorDataType: IndicatorDataType) => {
    const date = recordingDate || new Date();

    const quarter = getQuarter(date);
    const year = getYear(date);
    const semester = getSemester(date);

    switch (indicatorDataType) {
        case IndicatorDataType.AURA_REPORTING: return 'Q' + quarter + ' ' + year;
        case IndicatorDataType.AURA_COMPLEMENTARY: return 'Year' + ' ' + year;
        case IndicatorDataType.DNAAS_USP: return 'Year' + ' ' + year;
    }
}
